/*====================================*\

    @define hero;


\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.hero {
    border-bottom: 10px solid $color-responsible-aqua;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.hero__text-wrap {
    position: relative;
    padding: $site-grid-spacing * 2;
    // scss-lint:disable DuplicateProperty
    background-color: $color-white;
    background-color: rgba($color-white, 0.9);
    // scss-lint:enable DuplicateProperty
    margin-top: $site-grid-spacing * 2;

    @media ($mq-large) {
        left: -$site-grid-spacing;
    }
}

.hero__title {
    margin-bottom: $site-grid-spacing;
}

.hero__text {
    font-size: $font-size-larger;
}
