/*====================================*\

    @define custom-select;

    Progressively-enhanced fancy dropdown

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.custom-select {
    margin-top: 0 !important;
    position: relative;
    background-color: $color-white;
    display: inline-block;
    width: 100%;

    &:after {
        @include icon-font;

        content: '\e908'; // Caret-down
        position: absolute;
        top: 50%;
        right: $site-grid-spacing;
        text-align: center;
        transform: translateY(-50%);
        pointer-events: none;
    }

    select {
        line-height: 1.125;
        width: 100%;
        border: 2px solid $input-color-border;
        border-radius: 0;
        background: none;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-right: $site-grid-spacing * 2;

        &:focus {
            border-color: $input-color-border-focus;
            outline: none;
        }

        &.invalid {
            border-color: $input-color-border-invalid;
        }
    }

    // Hide in IE - see http://stackoverflow.com/questions/20541306/how-to-write-a-css-hack-for-ie-11
    @media screen and (min-width:0\0) and (min-resolution: +72dpi) {

        &:after {
            display: none;
        }

        select {
            padding-right: $site-grid-spacing;
        }
    }
}
