/*====================================*\

    @define breadcrumb;

    Navigation trail

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$breadcrumb-padding-v: $site-grid-spacing;
$breadcrumb-padding-h: $site-grid-spacing * 1.5;

/*------------------------------------*\
    Styles
\*------------------------------------*/

.breadcrumb {
    padding-top: $breadcrumb-padding-v;
    padding-right: $breadcrumb-padding-h;
    padding-bottom: $breadcrumb-padding-v;
    padding-left: 0;
    border-bottom: 1px solid $color-gray-6;
}

.breadcrumb__list {
    margin-left: 0;
}

.breadcrumb__item {
    display: inline-block;

    &:before {
        content: '>'; // or '/' or an icon
        display: inline-block;
        color: $color-gray-4;
    }
}

.breadcrumb__item--home {

    &:before {
        content: none;
    }
}
