/*====================================*\

    @define topic-box;

    Link box showing an indiviual issue type

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$topic-box-color-bg: $color-white;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.topic-box {
    background-color: $topic-box-color-bg;
}


// Colour variations
.topic-box--yellow {
    .topic-box__img-wrap {
        background-color: $color-heritage-yellow;
    }
}

.topic-box--green {
    .topic-box__img-wrap {
        background-color: $color-responsible-aqua;
    }
}

.topic-box--blue {
    .topic-box__img-wrap {
        background-color: $color-light-blue;
    }
}

.topic-box--pink {
    .topic-box__img-wrap {
        background-color: $color-inventive-pink;
    }
}

.topic-box__link {
    text-decoration: none;
    color: inherit;
}

.topic-box__img-wrap {
    float: left;
    width: 33.33%;
    margin-right: 20px;
    position: relative;

    @media($mq-x-small) {
        min-height: 135px;
    }
}

.topic-box__img {
    display: block;
    width: 100%;
    height: 66px;

    @media($mq-x-small) {
        position: absolute;
        bottom: 0;
        height: 130px;
    }

    .ie8 & {
        position: static;
        width: auto;
        margin: 0 auto;
    }
}

.topic-box__title {
    margin-top: 10px;
    margin-left: 20px;
    line-height: 1.2;
    font-size: 20px;

    @media($mq-x-small) {
        font-size: 28px;
    }
}

.topic-box__text-wrap {
    clear: both;
    padding: 10px 0;
    background-color: $color-gray-7;

    @media($mq-x-small) {
        clear: none;
        padding: 10px 20px;
        background-color: transparent;
    }
}
