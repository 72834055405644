/*====================================*\

    @define progress-bar;

   A bar indicating current progress through tirage process

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$progress-bar-color-bg: $color-blue-lighter;
$progress-bar-color-bg-active: $color-heritage-yellow;
$progress-bar-item-padding: 4px;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.progress-bar {
    margin-bottom: $site-grid-spacing;
    margin-left: -$progress-bar-item-padding !important;
    margin-right: -$progress-bar-item-padding !important;
    width: calc(100% + (2 * #{$progress-bar-item-padding})) !important;
}

.progress-bar__inner {
    display: table;
    border-collapse: separate;
    border-spacing: $progress-bar-item-padding;
    height: $site-grid-spacing;
    width: 100%;
}

.progress-bar__item {
    display: table-cell;
    height: 100%;
    background-color: $progress-bar-color-bg;

    &.active {
        background-color: $progress-bar-color-bg-active;
    }
}

.progress-bar__text {
    margin-left: $progress-bar-item-padding;
}
