/*====================================*\

    SETTINGS - FONTS

\*====================================*/

//scss-lint:disable SpaceAfterVariableName

/*------------------------------------*\
    Font stacks
\*------------------------------------*/

$font-family-body: 'Open Sans', "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
$font-family-heading: 'Open Sans', "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;

/*------------------------------------*\
    Font sizes
\*------------------------------------*/

$font-size-smaller: 12px;
$font-size-small  : 14px;
$font-size-normal : 16px;
$font-size-large  : 18px;
$font-size-larger : 20px;

$font-size-root   : $font-size-normal !default;

/*------------------------------------*\
    Heading sizes
\*------------------------------------*/

$heading-size-1: $font-size-root * 2.5;
$heading-size-2: $font-size-root * 2;
$heading-size-3: $font-size-root * 1.75;
$heading-size-4: $font-size-root * 1.5;
$heading-size-5: $font-size-root * 1.25;
$heading-size-6: $font-size-root * 1;

/*------------------------------------*\
    Line heights
\*------------------------------------*/

$line-height-multiplier: 1.5 !default;

$line-height-normal: $font-size-normal * $line-height-multiplier;

//scss-lint:disable SpaceAfterVariableName
