/*====================================*\

    BASE - LINKS

\*====================================*/

a {
    color: $color-link;
    text-decoration: none;
    border-bottom: 0.075em solid currentColor;
    transition: color $transition-time $transition-ease,
                background-color $transition-time $transition-ease,
                border-color $transition-time $transition-ease;

    &:focus {
        color: $color-link-active;
    }

    &:hover {
        color: $color-link-active;
    }

    &:active {
        color: $color-link-active;
    }

    .ie8 & {
        text-decoration: underline;
    }
}
