/*====================================*\

  @define utilities;

  Link style that inherits existing styles

\*====================================*/

.u-unstyled-link {
    display: inherit;
    color: inherit;
    text-decoration: inherit;
}
