// --------------------------------------------------
// reflex grid
// --------------------------------------------------

// Reset the box-sizing so our grid works properly
* {
    @include box-sizing(border-box);
}
*:before,
*:after {
    @include box-sizing(border-box);
}

// --------------------------------------------------
// reflex grid
// --------------------------------------------------

.#{$reflex-prefix}grid {
    @include display-flex();
    @include flex-wrap();
    padding: 0;
    margin: 0;
    position: relative;
    width: 100%;
    max-width: $reflex-max-width;

    @include setupWhitespace();

    &:before,
    &:after {
        @include resetWhitespace();
        max-width: 100%;
    }

    *:before,
    *:after {
        @include resetWhitespace();
    }

    // handle nested grids
    & .#{$reflex-prefix}grid {
        @include flex(1, 1, auto);
    }

    // handle when lists are used as a grid
    list-style-type: none;
}

// --------------------------------------------------
// reflex grid__col defaults
// --------------------------------------------------

[class*="#{$reflex-prefix}grid__col-"] {
    @include setupCols();
}

// --------------------------------------------------
// reflex grid__cell defaults
// --------------------------------------------------

.#{$reflex-prefix}grid__cell {
    position: relative;
    display: block;
    @include flex(1, 1, auto);
}

// ie11 hack
_:-ms-fullscreen, :root .grid__cell {
    width: 100%;
}

// --------------------------------------------------
// reflex grid generation
// --------------------------------------------------

@include make-reflex-grid(grid__col-);

@media (min-width: $reflex-xs) {
    @include make-reflex-grid(grid__col-xs-);
}

@media (min-width: $reflex-sm) {
    @include make-reflex-grid(grid__col-sm-);
}

@media (min-width: $reflex-md) {
    @include make-reflex-grid(grid__col-md-);
}

@media (min-width: $reflex-lg) {
    @include make-reflex-grid(grid__col-lg-);
}

@media (min-width: $reflex-xlg) {
    @include make-reflex-grid(grid__col-xlg-);
}


// --------------------------------------------------
// reflex grid__col-auto
// --------------------------------------------------

.#{$reflex-prefix}grid__col-auto {
    @include setupAutoCols();
}

@media (min-width: $reflex-xs) {
    .#{$reflex-prefix}grid__col-xs-auto {
        @include setupAutoCols();
    }
}

@media (min-width: $reflex-sm) {
    .#{$reflex-prefix}grid__col-sm-auto {
        @include setupAutoCols();
    }
}

@media (min-width: $reflex-md) {
    .#{$reflex-prefix}grid__col-md-auto {
        @include setupAutoCols();
    }
}

@media (min-width: $reflex-lg) {
    .#{$reflex-prefix}grid__col-lg-auto {
        @include setupAutoCols();
    }
}

@media (min-width: $reflex-xlg) {
    .#{$reflex-prefix}grid__col-xlg-auto {
        @include setupAutoCols();
    }
}

// --------------------------------------------------
// reflex order helpers generation
// --------------------------------------------------

@include make-grid--order-helpers();
