/*====================================*\

    BASE - FORMS

\*====================================*/

//scss-lint:disable QualifyingElement

/*------------------------------------*\
    Variables
\*------------------------------------*/

$input-color-border: $color-heritage-blue;
$input-color-border-focus: $color-heritage-yellow;
$input-color-border-invalid: $color-red;
$input-color-bg-disabled: $color-gray-6;
$input-color-text-disabled: $color-gray-3;

$validation-error-color-text: $color-red;

$file-upload-color-text: $color-white;
$file-upload-color-bg: $color-link;

/*------------------------------------*\
    Containers
\*------------------------------------*/

form {}

fieldset {
    border: 0;
}

legend {}

label {}

/*------------------------------------*\
    Select
\*------------------------------------*/

select,
select[multiple],
select[multiple="multiple"] {
    max-width: 100%;
    width: 100%;
}

/*------------------------------------*\
    Text inputs
\*------------------------------------*/

select,
select[multiple],
select[multiple="multiple"],
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="phone"],
input[type="tel"],
input[type="number"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="color"],
input[type="time"],
input[type="search"],
input[type="datetime-local"] {
    box-shadow: none;
    padding: $site-grid-spacing * 0.75 $site-grid-spacing;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="phone"],
input[type="tel"],
input[type="number"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="color"],
input[type="time"],
input[type="search"],
input[type="datetime-local"] {

    &[disabled] {
        cursor: not-allowed !important;
        color: $input-color-text-disabled;
        background-color: $input-color-bg-disabled;
        resize: none;
    }
}

input[type="range"] {
    position: relative;
    top: 3px;
}

/*------------------------------------*\
    Radio/Checkbox
\*------------------------------------*/

select,
input[type="checkbox"],
input[type="radio"] {

    &[disabled] {
        cursor: default;
    }
}

input[type="radio"],
input[type="checkbox"] {
    width: auto;
    margin-right: $site-grid-spacing / 2;
}

.checkbox,
.radio {
    display: table;
    font-weight: bold;
    padding: 0.5 * $site-grid-spacing $site-grid-spacing;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }
}

.checkbox--inline,
.radio--inline {
    margin-bottom: 0;
    display: inline-block;
    margin-right: $site-grid-spacing / 2;
}

/*------------------------------------*\
    input-wrap / fieldset
\*------------------------------------*/

.input-wrap {}

.input-wrap--date {
    fieldset {
        width: 6em;
        display: inline-block;
    }
}

* + .input-wrap,
* + fieldset {
    margin-top: $line-height-normal;
}

/*------------------------------------*\
    placeholder text
\*------------------------------------*/

//scss-lint:disable VendorPrefix
::input-placeholder {
    font-style: italic;
}

::-webkit-input-placeholder {
    font-style: italic;
}

::-moz-placeholder {
    font-style: italic;
}

:-ms-input-placeholder {
    font-style: italic;
}
//scss-lint:enable VendorPrefix

/*------------------------------------*\
    file upload
\*------------------------------------*/

::-webkit-file-upload-button {
    color: $file-upload-color-text;
    background: $file-upload-color-bg;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 6px 12px;
    -webkit-appearance: none;
    font-weight: bold;
    transition: background $transition-time ease-in-out;

    &:hover {
        cursor: pointer;
    }
}

/*------------------------------------*\
    labels
\*------------------------------------*/

label {
    display: block;
    max-width: 100%;
    font-weight: bold;
    margin-bottom: $site-grid-spacing / 2;

    .optional {
        color: $color-gray-2;
        font-weight: normal;
    }

    .required {
        color: $color-red;
    }
}

/*------------------------------------*\
    legends
\*------------------------------------*/

legend {
    display: block;
    max-width: 100%;
    margin-bottom: $site-grid-spacing / 2;
    font-weight: bold;

    .required {
        color: $color-red;
    }
}

/*------------------------------------*\
    hints
\*------------------------------------*/

.hint {
    color: $color-gray-2;
    margin-top: -8px;
    margin-bottom: 8px;
}

/*------------------------------------*\
    form

    inputs wrapped in the form class will
    have extra styles
\*------------------------------------*/

.form {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"],
    input[type="phone"],
    input[type="tel"],
    input[type="number"],
    input[type="datetime"],
    input[type="date"],
    input[type="month"],
    input[type="color"],
    input[type="time"],
    input[type="search"],
    input[type="range"],
    input[type="datetime-local"],
    textarea {
        display: block;
        width: 100%;
        border: 2px solid $input-color-border;
        transition: border ease $transition-time;

        &.invalid {
            border-color: $input-color-border-invalid;
        }

        &:focus {
            border-color: $input-color-border-focus;
            outline: none;
        }
    }

    input[type="file"] {
        display: block;
    }

    // NOTE: In HTML5, the small element is used to represent side-comments and small print
    small {
        display: block;
        font-size: $font-size-small;
        margin-top: 4px;
    }
}

/*------------------------------------*\
    validation errors
\*------------------------------------*/

.validation-error {
    margin-top: $site-grid-spacing / 2;
    color: $validation-error-color-text;

    // Override lobotomized owl
    input + & {
        margin-top: $site-grid-spacing / 2;
    }
}

.parsley-errors-list {
    margin-top: $site-grid-spacing / 2 !important;
    margin-left: 0;
    color: $validation-error-color-text;
    padding: 0;
    list-style-type: none;
    opacity: 0;
    transition: all 0.3s ease-in;
}

.parsley-errors-list.filled {
    opacity: 1;
}

//scss-lint:enable QualifyingElement
