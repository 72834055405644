/*===========================================================================*\

    BASE - HEADINGS

\*===========================================================================*/

/*---------------------------------------------------------------------------*\
    Base defaults, these get overwritten in settings.scss,
    only here as fallbacks
\*---------------------------------------------------------------------------*/

$heading-size-1: 3.998em !default;
$heading-size-2: 2.827em !default;
$heading-size-3: 1.999em !default;
$heading-size-4: 1.414em !default;
$heading-size-5: 1em !default;
$heading-size-6: 1em !default;

/*---------------------------------------------------------------------------*\
    Mixins if we need to make various types of headings
\*---------------------------------------------------------------------------*/

@mixin h1() {
    font-size: $heading-size-1;
    font-weight: 800;
}

@mixin h2() {
    font-size: $heading-size-2;
    font-weight: 800;
}

@mixin h3() {
    font-size: $heading-size-3;
    font-weight: 800;
}

@mixin h4() {
    font-size: $heading-size-4;
    font-weight: 800;
}

@mixin h5() {
    font-size: $heading-size-5;
    font-weight: 800;
}

@mixin h6() {
    font-size: $heading-size-6;
    font-weight: 800;
}

/*---------------------------------------------------------------------------*\
    Styles
\*---------------------------------------------------------------------------*/

h1,
.h1 {
    @include h1();
}

h2,
.h2 {
    @include h2();
}

h3,
.h3 {
    @include h3();
}

h4,
.h4 {
    @include h4();
}

h5,
.h5 {
    @include h5();
}

h6,
.h6 {
    @include h6();
}
