/*====================================*\

    @define listing-item;

    Box showing entry on listing page

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$listing-item-color-bg: $color-gray-8;
$listing-item-color-text: $color-text;

$listing-item-logo-padding: $site-grid-spacing;
$listing-item-logo-width: 6 * $site-grid-spacing;

/*------------------------------------*\
    Layout
\*------------------------------------*/

.l-listing-item__img-wrap {
    width: 100%;
}

.l-listing-item__text-wrap {
    position: relative;
    padding: $site-grid-spacing;
    min-height: (2 * $listing-item-logo-padding) + $listing-item-logo-width;
}
/*------------------------------------*\
    Styles
\*------------------------------------*/

.listing-item {
    min-height: 100%;
}

.listing-item__link {
    color: $listing-item-color-text;
    background-color: $listing-item-color-bg;

    &:hover,
    &:focus,
    &:active {
        color: $listing-item-color-text;
        background-color: darken($listing-item-color-bg, 5);
    }
}

.listing-item__img {
    width: 100%;
    display: block;
    flex-shrink: 0;
}

.listing-item__title {
    display: block;
    color: $listing-item-color-text;
    line-height: 1.2;
    font-size: $font-size-larger;
    margin-bottom: $site-grid-spacing / 2;
}

.listing-item__title--center {
    text-align: center;
}

.listing-item__date {
    font-weight: bold;
    margin-bottom: 20px;
}
