/*====================================*\

    SETTINGS - COLOURS

\*====================================*/

/*------------------------------------*\
    Colour definitions
\*------------------------------------*/

$color-white: #fff;
$color-black: #000;

// Brand colours
$color-heritage-blue: #004b88;
$color-heritage-yellow: #fcbb69;

$color-responsible-aqua: #a6d6ae;
$color-responsible-purple: #57486b;

$color-inventive-teal: #006278;
$color-inventive-pink: #fcceba;

$color-generous-green: #00553f;
$color-generous-mauve: #c2bdde;

$color-red: #df3034;
$color-purple-dark: #53388d;
$color-blue: #003c64;
$color-blue-lighter: #caeaff;

// Tints
$color-heritage-blue-lighter: #a2d5ff;
$color-heritage-blue-lightest: #f2f8ff;

// Greys
$color-gray-0: lighten($color-black, 12.5%); // #202020
$color-gray-1: lighten($color-black, 25%);   // #404040
$color-gray-2: lighten($color-black, 37.5%); // #606060
$color-gray-3: lighten($color-black, 50%);   // #808080
$color-gray-4: lighten($color-black, 62.5%); // #9F9F9F
$color-gray-5: lighten($color-black, 75%);   // #BFBFBF
$color-gray-6: lighten($color-black, 87.5%); // #DFDFDF
$color-gray-7: lighten($color-black, 93.5%); // #EEEEEE
$color-gray-8: lighten($color-black, 95%);   // #F2F2F2

$color-light-blue: #ccdbe7;


/*------------------------------------*\
    Global Colour uses
\*------------------------------------*/

// Font colours

$color-text: $color-heritage-blue;
$color-heading: inherit;

$color-link: $color-blue;
$color-link-active: $color-heritage-blue;
$color-link-visited: $color-purple-dark;

