/*====================================*\

    @define editorial;

    Editorial styles

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/
.editorial {}

.editorial__header {
    border-bottom: 1px solid $color-gray-7;
    position: relative;
    z-index: 100;
}

.editorial__title {
    line-height: 2.5 * $line-height-normal;
}

.editorial__lead {
    font-size: $font-size-larger;
}
