// --------------------------------------------------
// reflex mixins
// --------------------------------------------------
//
// --------------------------------------------------
// reflex grid generation mixins
// --------------------------------------------------

@mixin make-reflex-grid($class) {
    @include loop-reflex-columns($reflex-columns, $class, width);
}

@mixin calc-reflex-columns($index, $class, $type) {
    @if $type == width and $index > 0 {
        .#{$reflex-prefix}#{$class}#{$index} {
            width: percentage(($index / $reflex-columns));
            *width: percentage(($index / $reflex-columns)) - 0.1;

            //for ie6 support you can uncomment this line but it will increase css filesize dramatically
            //@include setupCols();
        }
    }
}

@mixin loop-reflex-columns($index, $class, $type) {
    @if $index >= 0 {
        @include calc-reflex-columns($index, $class, $type);
        // next iteration
        @include loop-reflex-columns(($index - 1), $class, $type);
    }
}

// defaults for all cols
@mixin setupCols() {
    @include display-flex();
    @include flex-direction(column);
    @include resetTextAlign();
    @include resetWhitespace();
    position: relative;
    width: 100%;
    vertical-align: top;
    padding: $reflex-grid-spacing;
}

// defaults for auto cols
@mixin setupAutoCols() {
    @include flex(1, 0, 0px); // a unit on last value is required by IE10-11
    width: auto !important;
    max-width: 100%;
}

// --------------------------------------------------
// inline-block specific mixins
// --------------------------------------------------

@mixin setupWhitespace() {
    letter-spacing: -0.31em !important;
    *letter-spacing: normal !important;
    word-spacing: -0.43em !important;
}

@mixin resetWhitespace() {
    letter-spacing: normal;
    word-spacing: normal;
    white-space: normal;
}

@mixin resetTextAlign() {
    //
    // We want to reset any text-align properties set by the grid
    // (required for the inline-block fallback)
    // but we don't want to override any text-align properties
    // set on the individual grid__col-x element
    // or on any of it's child elements
    //
    // The "initial" property is not widely supported so a default of "left" and "start" is provided
    // http://caniuse.com/#search=initial
    //
    // 1) set to left by default (works everywhere)
    // 2) set to start (respects right to left text)
    // 3) set to initial
    //
    text-align: left;
    text-align: start;
    text-align: initial;
    -moz-text-align-last: left;
    -moz-text-align-last: start;
    -moz-text-align-last: initial;
    text-align-last: left;
    text-align-last: start;
    text-align-last: initial;
}

// --------------------------------------------------
// order class generation mixins
// --------------------------------------------------

@mixin order($order: 0) {
    -ms-flex-order: $order;
    -webkit-order: $order;
    order: $order;
}

@mixin make-grid--order-helpers() {
    @include loop-grid--order-helpers($reflex-columns);
}

@mixin loop-grid--order-helpers($index) {
    @if $index >= 0 {
        .#{$reflex-prefix}grid--order-#{$index} {
            @include order($index);
        }
        // next iteration
        @include loop-grid--order-helpers(($index - 1));
    }
}

// --------------------------------------------------
// reflex modifier mixins
// --------------------------------------------------

//
// display
// ---

@mixin display-flex-only() {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin display-flex() {
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    *display: inline;
    zoom: 1;
}

//
// flex
// ---

@mixin flex($grow: 0, $shrink: 1, $basis: auto) {
    @if $basis == 0px {
        -ms-flex: $grow $shrink auto; //ie10 needs auto
        -webkit-flex: $grow $shrink $basis;
        flex: $grow $shrink $basis;
    }
}

@mixin flex($grow: 0, $shrink: 1, $basis: auto) {
    -ms-flex: $grow $shrink $basis;
    -webkit-flex: $grow $shrink $basis;
    flex: $grow $shrink $basis;
}

@mixin flex-grow($grow:1) {
    -ms-flex-positive: $grow;
    -webkit-flex-grow: $grow;
    flex-grow: $grow;
}

@mixin flex-shrink($shrink:1) {
    -ms-flex-negative: $shrink;
    -webkit-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

@mixin flex-basis($basis:auto) {
    -ms-flex-preferred-size: $basis;
    -webkit-flex-basis: $basis;
    flex-basis: $basis;
}

@mixin flex-flow($direction: row, $wrap: nowrap) {
    -ms-flex-wrap: $wrap;
    -ms-flex-direction: $direction;
    -webkit-flex-flow: $direction $wrap;
    flex-flow: $direction $wrap;
}

@mixin flex-wrap($wrap: wrap) {
    -ms-flex-wrap: $wrap;
    -webkit-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flex-direction($direction: row) {
    -ms-flex-direction: $direction;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
}

//
// align
// ---

@mixin align-items($align: stretch) {
    @include ms-align-items($align);
    -webkit-align-items: $align;
    align-items: $align;
}

@mixin align-self($align: stretch) {
    @include ms-align-self($align);
    -webkit-align-self: $align;
    align-self: $align;
}

@mixin align-content($align: stretch) {
    @include ms-align-content($align);
    -webkit-align-content: $align;
    align-content: $align;
}

//
// ie10 syntax for align
// ---

@mixin ms-align-items($align) {
    @if $align == flex-start {
        -ms-flex-align: start;
    }
}

@mixin ms-align-items($align) {
    @if $align == flex-end {
        -ms-flex-align: end;
    }
}

@mixin ms-align-items($align) {
    -ms-flex-align: $align;
}

@mixin ms-align-self($align) {
    @if $align == flex-start {
        -ms-flex-item-align: start;
    }
}

@mixin ms-align-self($align) {
    @if $align == flex-end {
        -ms-flex-item-align: end;
    }
}

@mixin ms-align-self($align) {
    -ms-flex-item-align: $align;
}

@mixin ms-align-content($align) {
    @if $align == flex-start {
        -ms-flex-line-pack: start;
    }
}

@mixin ms-align-content($align) {
    @if $align == flex-end {
        -ms-flex-line-pack: end;
    }
}

@mixin ms-align-content($align) {
    -ms-flex-line-pack: $align;
}

//
// justify-content
//
// Uses "text-align" for the fallback inline-block grid
// "text-align" is globally supported and works on all rows except the last
// "text-align-last", where supported, handles the last line (and, happily, grids with only one row)
// ---

@mixin justify-content-start() {
    text-align: left;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}

@mixin justify-content-end() {
    text-align: right;
    -moz-text-align-last: right;
    text-align-last: right;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

@mixin justify-content-center() {
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

@mixin justify-content-space-between() {
    text-align: justify;
    -moz-text-align-last: justify;
    text-align-last: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

@mixin justify-content-space-around() {
    text-align: justify;
    -moz-text-align-last: justify;
    text-align-last: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

// --------------------------------------------------
// other mixins
// --------------------------------------------------

@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}
