/*====================================*\

    @define validation;

    A list of form validation issues

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$validation-error-color-border: $color-red;
$validation-error-color-text: $color-red;
$validation-color-bg: $color-white;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.validation {
    padding: $site-grid-spacing * 0.75 $site-grid-spacing;
    background-color: $validation-color-bg;
}

.validation--error {
    border: 2px solid $validation-error-color-border;
    border-radius: 6px;

    .validation__link {
        color: $validation-error-color-text;
    }
}
