/*====================================*\

    @define btn;

    Button

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/
$btn-color-text: $color-heritage-blue;
$btn-color-bg: $color-responsible-aqua;
$btn-color-border: $color-heritage-blue;

$btn-padding-vertical: 6px;
$btn-padding-horizontal: 18px;

/*------------------------------------*\
    Styles
\*------------------------------------*/

.btn {
    display: table;
    position: relative;
    color: $btn-color-text;
    background-color: $btn-color-bg;
    padding: $btn-padding-vertical $btn-padding-horizontal;
    text-decoration: none;
    font-weight: bold;
    font-size: $font-size-larger;
    border: 2px solid $color-heritage-blue;
    border-radius: 6px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        color: $btn-color-text;
        background-color: lighten($btn-color-bg, 10);
    }
}

.btn--outline {
    background: transparent;
    border: 2px solid $btn-color-bg;

    color: $btn-color-bg;
}

.btn--white {
    border-color: $color-white;
    color: $color-white;
}

.btn--wide {
    width: 100%;
}

.btn--back {
    display: inline-block;
    background: transparent;
    border-color: transparent;
    padding-left: $site-grid-spacing * 2;

    &:before {
        @include icon-font;

        content: '\e906'; // Caret-left
        position: absolute;
        top: 10px;
        left: 16px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $color-heritage-blue-lightest;
        border-color: $color-heritage-blue-lightest;
    }
}

.btn--submit {
    display: inline-block;
}
