/*====================================*\

    @define advice-box;

    Box

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$advice-box-color-border: $color-heritage-blue;
$advice-box-color-bg: $color-white;
/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.advice-box {
    background-color: $color-white;
    border-top: ($site-grid-spacing / 2) solid $advice-box-color-border;
    font-size: 1.2em;
}
