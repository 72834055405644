/*====================================*\

    @define site footer;

    Footer block

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$site-footer-color-bg: $color-heritage-blue;
$site-footer-color-txt: $color-white;

$site-footer-padding-v: $site-grid-spacing * 2;
/*------------------------------------*\
    Layout
\*------------------------------------*/

.l-site-footer__col {
    text-align: center;

    @media($mq-small) {
        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }
}

/*------------------------------------*\
    Styles
\*------------------------------------*/

.site-footer {
    margin-top: $site-grid-spacing;
    padding-top: $site-footer-padding-v;
    padding-bottom: $site-footer-padding-v;
    background-color: $site-footer-color-bg;
    color: $site-footer-color-txt;
    text-align: center;
}

.site-footer__btn {
    margin: 0 auto $site-grid-spacing auto;

    @media($mq-small) {
        margin-right: 0;
    }
}

.site-footer__logo {
    display: inline-block;
    margin: ($site-grid-spacing * 2) auto;
    width: 185px;

    @media($mq-small) {
        margin-left: 0;
    }
}

.site-footer__text {

}

.site-footer__text--align-bottom {
    margin-top: auto;
}

.site-footer__link {
    color: $color-white;
    text-decoration: none;
    border-bottom: 0;

    &:hover {
        color: $color-white;
        border-bottom: 0.075em solid currentColor;

        .ie8 & {
            text-decoration: underline;
        }
    }
}
