/*====================================*\

    @define l-content-wrap;

    Enforce max-width across site

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$content-wrap-width: $site-max-width;
$content-wrap-padding: $site-grid-spacing;
$content-wrap-padding-sm: $site-grid-spacing * 2;
$content-wrap-padding-xl: 0;

/*------------------------------------*\
    Mixins
\*------------------------------------*/

@mixin l-content-wrap () {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $content-wrap-width;
    padding-left: $content-wrap-padding;
    padding-right: $content-wrap-padding;
    position: relative;
    clear: both;
}

/*------------------------------------*\
    Styles
\*------------------------------------*/

.l-content-wrap {
    @include l-content-wrap();

    @media($mq-small) {
        padding-left: $content-wrap-padding-sm;
        padding-right: $content-wrap-padding-sm;
    }

    @media($mq-x-large) {
        padding-left: $content-wrap-padding-xl;
        padding-right: $content-wrap-padding-xl;
    }
}

.l-content-wrap--pad-v {
    padding-left: $site-grid-spacing;
    padding-right: $site-grid-spacing;
}
