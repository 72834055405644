/*====================================*\

    SETTINGS - TRANSITIONS

\*====================================*/

/*------------------------------------*\
    Transition times
\*------------------------------------*/

$transition-time: 0.25s;

$transition-time-fast: 0.1s;

$transition-time-slow: 0.5s;


/*------------------------------------*\
    Transition easing
\*------------------------------------*/

$transition-ease: ease;
