//scss-lint:disable Indentation, StringQuotes, EmptyLineBetweenBlocks

@font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icomoon.eot?elmdhi');
    src: url('../../fonts/icomoon.eot?elmdhi#iefix') format('embedded-opentype'),
        url('../../fonts/icomoon.ttf?elmdhi') format('truetype'),
        url('../../fonts/icomoon.woff?elmdhi') format('woff'),
        url('../../fonts/icomoon.svg?elmdhi#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin icon-font() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
    @include icon-font;
}

.icon-menu:before {
  content: "\e904";
}
.icon-home:before {
  content: "\e903";
}
.icon-mail:before {
  content: "\e900";
}
.icon-caret-right:before {
  content: "\e905";
}
.icon-caret-left:before {
  content: "\e906";
}
.icon-caret-up:before {
  content: "\e907";
}
.icon-caret-down:before {
  content: "\e908";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\e902";
}

//scss-lint:enable Indentation, StringQuotes, EmptyLineBetweenBlocks
