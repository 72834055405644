/*====================================*\

    @define stripe;

    Coloured full-width stripe

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.stripe {
    height: $site-grid-spacing * 2;
}

.stripe--green {
    background-color: $color-responsible-aqua;
}

