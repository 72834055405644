/*====================================*\

    @define issue-form;

    Container for issue forms

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.issue-form {
    background-color: $color-white;
    margin-bottom: $line-height-normal;
}

// Only apply CSS if JS is enabled
.js .issue-form--sections {
    display: none;

    &.current {
        display: inherit;
    }
}

.issue-image {
    position: relative;
    right: $site-grid-spacing * 2;
    margin-top: $site-grid-spacing * 2;
    z-index: 100;
    max-width: 168px;
}
