/*====================================*\

    @define cookie-consent;

    Override CSS for Cookie Consent plugin

\*====================================*/

//scss-lint:disable SelectorFormat

/*------------------------------------*\
    Variables
\*------------------------------------*/


/*------------------------------------*\
    Styles
\*------------------------------------*/
.cc_container {
    font-family: $font-family-body !important;

    .cc_message {
        color: $color-text;
    }

    .cc_btn,
    .cc_btn:visited {
        color: $color-white !important;
        background-color: $color-link !important;
        border-radius: 0 !important;

        &:hover,
        &:active,
        &:focus {
            color: $color-white;
            background-color: lighten($color-link, 10);
        }
    }
}

//scss-lint:enable SelectorFormat
