/*====================================*\

    @define related;

    related teaser article block

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$related-color-text: $color-text;
$related-color-bg: $color-gray-7;

$related-padding: $site-grid-spacing; // 16px

/*------------------------------------*\
    Styles
\*------------------------------------*/
.related {}

.related__link {
    display: block;
    text-decoration: none;
    width: 100%;
    min-height: 100%;
}

.related__img {
    display: block;
    width: 100%;
}

.related__text-wrap {
    background-color: $related-color-bg;
    color: $related-color-text;
    padding: $related-padding;
}

.related__title {
    font-size: $font-size-large;
}
