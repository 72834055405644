/*====================================*\

    @define main-nav;

    Main top-level navigation

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$main-nav-color-bg: $color-heritage-blue;
$main-nav-color-link: $color-white;

/*------------------------------------*\
    Layout
\*------------------------------------*/



/*------------------------------------*\
    Styles
\*------------------------------------*/

.main-nav {
    background-color: $main-nav-color-bg;
    z-index: 3;
    position: relative;
}

.main-nav__inner {
    text-align: right;

    @media ($mq-medium) {
        text-align: left;
    }
}

.main-nav__list {
    margin-left: 0;
    list-style-type: none;
    display: none;
    text-align: left;
    padding-bottom: 8px;
    flex-wrap: wrap;
    align-content: flex-end;

    &.is-active {
        //scss-lint:disable DuplicateProperty
        display: block;
        display: flex;
        //scss-lint:enable DuplicateProperty
    }

    @media ($mq-medium) {
        padding-bottom: 0;
        display: block !important;
    }
}

.main-nav__item {
    display: flex;
    width: 50%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;

    @media ($mq-medium) {
        width: auto;
        display: inline-block;
        margin-right: $site-grid-spacing;
        padding-right: 0;
    }
}

.main-nav__link {
    color: $main-nav-color-link;
    text-decoration: none;
    font-weight: bold;
    border-bottom-color: transparent;

    &:hover,
    &:focus,
    &:active {
        color: $main-nav-color-link;
        border-bottom: 0.075em solid currentColor;
    }
}

.main-nav__menu-toggle {
    display: inline-block;
    position: relative;
    background: transparent;
    color: $color-white;
    border: 2px solid $color-white;
    border-radius: 6px;
    font-weight: bold;
    line-height: 1;
    padding: 12px 48px 12px 16px;
    margin-top: 6px;
    margin-bottom: 6px;
    cursor: pointer;

    .icon {
        position: absolute;
        font-size: 30px;
        top: 4px;
        right: 8px;
    }

    @media ($mq-medium) {
        display: none;
    }
}
