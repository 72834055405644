/*====================================*\

    @define l-sub-grid;

    A nested grid that uses negative margins

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$content-wrap-width: $site-max-width;
$content-wrap-padding: 0;

/*------------------------------------*\
    Mixins
\*------------------------------------*/

@mixin l-sub-grid () {
    margin-left: -$site-grid-spacing !important;
    margin-right: -$site-grid-spacing !important;
    width: calc(100% + (2 * #{$site-grid-spacing})) !important;
}

/*------------------------------------*\
    Styles
\*------------------------------------*/

.l-sub-grid {
    @include l-sub-grid();
}
