/*====================================*\

    @define share-controls;

    Group of buttons for sharing content

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$share-controls-color-text: $color-white;
$share-controls-font-size: $font-size-small;

$share-controls-color-fb: #3d5ca2;
$share-controls-color-twitter: #4eb1e4;
$share-controls-color-email: $color-gray-1;

/*------------------------------------*\
    Layout
\*------------------------------------*/


/*------------------------------------*\
    Styles
\*------------------------------------*/

.share-controls {
    margin-top: 0;
    margin-left: 0;
    position: relative;
    list-style-type: none;
}

.share-controls__item {
    display: inline-block;
}

.share-controls__button {
    display: inline-block;
    position: relative;
    font-family: $font-family-body;
    font-size: $share-controls-font-size;
    color: $share-controls-color-text;
    text-decoration: none;
    line-height: 1;
    padding: 8px 56px 8px 8px;

    .icon {
        position: absolute;
        right: 10px;
        top: 6px;
        font-size: $font-size-normal;
    }

    &:hover,
    &:active,
    &:focus {
        color: $share-controls-color-text;
    }
}

.share-controls__button--facebook {
    background: $share-controls-color-fb;

    &:hover,
    &:active,
    &:focus {
        background: darken($share-controls-color-fb, 10);
    }
}

.share-controls__button--twitter {
    background: $share-controls-color-twitter;

    &:hover,
    &:active,
    &:focus {
        background: darken($share-controls-color-twitter, 10);
    }
}

.share-controls__button--email {
    background: $share-controls-color-email;

    &:hover,
    &:active,
    &:focus {
        background: darken($share-controls-color-email, 10);
    }
}
