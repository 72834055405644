/*====================================*\

    @define validation;

    A list of form validation issues

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$notice-color-border: $color-responsible-aqua;
$notice-color-text: $color-heritage-blue;
$notice-color-bg: $color-white;

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.message {
    padding: $site-grid-spacing * 0.75 $site-grid-spacing;
    background-color: $notice-color-bg;
}

.message--notice {
    border: 2px solid $notice-color-border;
    border-radius: 6px;

    .validation__link {
        color: $notice-color-text;
    }
}
