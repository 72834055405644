/*====================================*\

    @define u-hide;

    Hide at certain media queries

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/



/*------------------------------------*\
    Styles
\*------------------------------------*/

.u-hide {}

.u-hide--lt-xs {
    @media ($mq-x-small-only) {
        display: none;
    }
}

.u-hide--lt-sm {
    @media ('max-width: ' + $bp-small) {
        display: none;
    }
}
