/*====================================*\

    @define sub-nav;

    Local navigation

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

// Top level list
$sub-nav-color-bg: $color-white;
$sub-nav-color-border-b: $color-gray-7;

// List item
$sub-nav-item-padding-v: $line-height-normal / 2; // vertical
$sub-nav-item-padding-h: $line-height-normal; // horizontal

// Links
$sub-nav-color-link-txt: $color-text;
$sub-nav-color-link-txt-active: $color-text;
$sub-nav-color-link-bg: $color-text;
$sub-nav-color-link-bg-active: $color-heritage-blue-lightest;
$sub-nav-color-link-bg-hover: $color-gray-8;

/*------------------------------------*\
    Layout
\*------------------------------------*/

.l-sub-nav-wrap--full {
    display: none;

    @media ($mq-small) {
        display: inline-block;
    }
}

.l-sub-nav-wrap--mobile {
    display: block;

    @media ($mq-small) {
        display: none;
    }
}

/*------------------------------------*\
    Styles
\*------------------------------------*/

.sub-nav {
    background-color: $sub-nav-color-bg;
    position: relative;
}

// 1st level
.sub-nav__list {
    list-style-type: none;
    margin-left: 0;
}

// 2nd level
.sub-nav__list .sub-nav__list {
    margin-left: $site-grid-spacing;
}

// 3rd level
.sub-nav__list .sub-nav__list .sub-nav__list {}

.sub-nav__item {

    &.active {

        >.sub-nav__link {
            background: $sub-nav-color-link-bg-active;
        }
    }
}

.sub-nav__link {
    display: block;
    padding-top: $sub-nav-item-padding-v;
    padding-right: $sub-nav-item-padding-h;
    padding-bottom: $sub-nav-item-padding-v;
    padding-left: $sub-nav-item-padding-h;
    color: $sub-nav-color-link-txt;
    text-decoration: none;
    border-bottom: 1px solid $sub-nav-color-border-b;
    transition: none;

    &:hover,
    &:active,
    &:focus {
        color: inherit;
        background: $sub-nav-color-link-bg-hover !important;
    }
}
