/*====================================*\

    @define boxout;

    Text box separate from main body

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$boxout-padding: $line-height-normal; // 24px

$boxout-color-text: $color-text;
$boxout-color-bg: $color-gray-7;

/*------------------------------------*\
    Styles
\*------------------------------------*/

.boxout {
    background-color: $boxout-color-bg;
    padding: $boxout-padding;
}

.boxout__text {
    color: $boxout-color-text;
}
