/*===========================================================================*\

    BASE - LISTS

\*===========================================================================*/

/*---------------------------------------------------------------------------*\
    Base defaults, these get overwritten in settings.scss,
    only here as fallbacks
\*---------------------------------------------------------------------------*/

$list-margin: 20px !default;

/*---------------------------------------------------------------------------*\
    Ordered/unordered lists
\*---------------------------------------------------------------------------*/

/* Remove extra vertical spacing when nesting lists. */
li > ul,
li > ol {
    margin-bottom: 0;
    margin-top: 0 !important;
}

ul,
ol,
ul ul,
ol ol,
ul ol,
ol ul {
    margin-left: $list-margin;
}

ol ol li {
    list-style-type: lower-alpha;
}

//scss-lint:disable SelectorDepth
ol ol ol li {
    list-style-type: lower-roman;
}
//scss-lint:enable SelectorDepth


/*---------------------------------------------------------------------------*\
    Definition lists
\*---------------------------------------------------------------------------*/

dl {
}

dl dt {
    margin-top: 0;
    font-weight: bold;
}

dl dd {
    margin-top: 0;
    margin-left: $list-margin;
}
