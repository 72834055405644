/*====================================*\

    BASE - VERTICAL FLOW

    give all block elements that follow an item a top margin
    http://alistapart.com/article/axiomatic-css-and-lobotomized-owls

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/



/*------------------------------------*\
    Styles
\*------------------------------------*/
.editorial {
    * + address,
    * + article,
    * + aside,
    * + audio,
    * + blockquote,
    * + canvas,
    * + div,
    * + dl,
    * + fieldset,
    * + figcaption,
    * + figure,
    * + footer,
    * + form,
    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6,
    * + header,
    * + hgroup,
    * + hr,
    * + main,
    * + nav,
    * + noscript,
    * + ol,
    * + output,
    * + p,
    * + pre,
    * + section,
    * + table,
    * + tfoot,
    * + ul,
    * + video {
         //scss-lint:disable DuplicateProperty
        margin-top: $line-height-multiplier * 16px;
        margin-top: $line-height-multiplier * 1rem;
         //scss-lint:enable DuplicateProperty
    }

    li + li {
        margin-top: 0;
    }

    /* Vertical Flow reset */
    noscript,
    br,
    caption,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        margin-top: 0;
    }
}

