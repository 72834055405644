
// --------------------------------------------------
// grid modifiers
// --------------------------------------------------

// --------------------------------------------------
// grid full bleed (no internal padding)
// --------------------------------------------------
.#{$reflex-prefix}grid--bleed {
    [class*="#{$reflex-prefix}grid__col-"] {
        padding: 0;
    }
}

// --------------------------------------------------
// flex-wrap
// --------------------------------------------------

.#{$reflex-prefix}grid--wrap {
    @include flex-wrap(wrap);
}

.#{$reflex-prefix}grid--no-wrap {
    @include flex-wrap(nowrap);
}

.#{$reflex-prefix}grid--wrap-reverse {
    @include flex-wrap(wrap-reverse);
}

// --------------------------------------------------
// flex-direction
// --------------------------------------------------

.#{$reflex-prefix}grid--direction-row {
    @include flex-direction(row);
}

.#{$reflex-prefix}grid--direction-row-reverse {
    @include flex-direction(row-reverse);
}

.#{$reflex-prefix}grid--direction-column {
    @include flex-direction(column);
}

.#{$reflex-prefix}grid--direction-column-reverse {
    @include flex-direction(column-reverse);
}

// --------------------------------------------------
// align-grid__col-cells (cross axis)
// --------------------------------------------------

.#{$reflex-prefix}grid--align-start {
    @include align-items(flex-start);
}

.#{$reflex-prefix}grid--align-end {
    @include align-items(flex-end);

    // fallback to legacy vertical-align
    [class*="#{$reflex-prefix}grid__col-"] {
        vertical-align: bottom;
    }
}

.#{$reflex-prefix}grid--align-center {
    @include align-items(center);

    // fallback to legacy vertical-align
    [class*="#{$reflex-prefix}grid__col-"] {
        vertical-align: middle;
    }
}

.#{$reflex-prefix}grid--align-baseline {
    @include align-items(baseline);

    // fallback to legacy vertical-align
    [class*="#{$reflex-prefix}grid__col-"] {
        vertical-align: baseline;
    }
}

// --------------------------------------------------
// align-content (cross axis)
// --------------------------------------------------

.#{$reflex-prefix}grid--align-content-start {
    @include align-content(flex-start);
}

.#{$reflex-prefix}grid--align-content-end {
    @include align-content(flex-end);

    // fallback to legacy vertical-align
    [class*="#{$reflex-prefix}grid__col-"] {
        vertical-align: bottom;
    }
}

.#{$reflex-prefix}grid--align-content-center {
    @include align-content(center);
}

.#{$reflex-prefix}grid--align-content-space-between {
    @include align-content(space-between);
}

.#{$reflex-prefix}grid--align-content-space-around {
    @include align-content(space-around);
}



// --------------------------------------------------
// align-self
// --------------------------------------------------

.#{$reflex-prefix}grid--align-self-stretch {
    @include align-self(stretch);
}

.#{$reflex-prefix}grid--align-self-start {
    @include align-self(flex-start);
}

.#{$reflex-prefix}grid--align-self-end {
    @include align-self(flex-end);
    vertical-align: bottom;
}

.#{$reflex-prefix}grid--align-self-center {
    @include align-self(center);
    vertical-align: middle;
}

.#{$reflex-prefix}grid--align-self-baseline {
    @include align-self(baseline);
    vertical-align: baseline;
}

// --------------------------------------------------
// justify-content (main axis)
// --------------------------------------------------

.#{$reflex-prefix}grid--justify-start {
    @include justify-content-start();
}

.#{$reflex-prefix}grid--justify-end {
    @include justify-content-end();

    .#{$reflex-prefix}grid__cell {
        @include resetTextAlign();
    }
}

.#{$reflex-prefix}grid--justify-center {
    @include justify-content-center();

    .#{$reflex-prefix}grid__cell {
        @include resetTextAlign();
    }
}

.#{$reflex-prefix}grid--justify-space-between {
    @include justify-content-space-between();

    .#{$reflex-prefix}grid__cell {
        @include resetTextAlign();
    }
}

.#{$reflex-prefix}grid--justify-space-around {
    @include justify-content-space-around();

    .#{$reflex-prefix}grid__cell {
        @include resetTextAlign();
    }
}

// --------------------------------------------------
// grid__col modifiers
// --------------------------------------------------

.#{$reflex-prefix}grid__col--bleed {
    padding: 0;
}

// --------------------------------------------------
// grid__cell modifiers
// --------------------------------------------------

.#{$reflex-prefix}grid__cell--padding-sm {
    padding: $reflex-cell-spacing-sm;
}

.#{$reflex-prefix}grid__cell--padding-md {
    padding: $reflex-cell-spacing-md;
}

.#{$reflex-prefix}grid__cell--padding-lg {
    padding: $reflex-cell-spacing-lg;
}

.#{$reflex-prefix}grid__cell-img {
    display: block;
    @include display-flex-only();
    @include flex(0, 0, auto);
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    width: 100%;
    height: auto;
}

.#{$reflex-prefix}grid__cell-footer {
    @include display-flex();
    width: 100%;
    margin-top: auto;
}
