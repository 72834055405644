/*====================================*\

    @define highlight;

    Editorial image block
    with optional caption

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

$highlight-color-bg: $color-gray-7;
$highlight-color-text: $color-text;

/*------------------------------------*\
    Layout
\*------------------------------------*/

.l-highlight__img-wrap {
    display: inline-block !important; // IE11 sucks
    padding: 0;
}

.l-highlight__text-wrap {

    // If this appears after the image
    + .l-highlight__img-wrap {
        padding-top: 0;
    }

    @media($mq-x-small) {
        padding-top: $site-grid-spacing;
    }
}

/*------------------------------------*\
    Styles
\*------------------------------------*/

.highlight {
    margin: $site-grid-spacing;
    background-color: $highlight-color-bg;
}

.highlight__img {
    display: block;
    width: 100%;
}

.highlight__title {
    line-height: 1.5;
}

.highlight__text {
    color: $highlight-color-text;
}

.highlight__button {
    margin-top: $line-height-normal;
}
