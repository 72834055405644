/*====================================*\

    @define site-header;

    This is the description of the component

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Layout
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.site-header {}

.site-header__logo-wrap {
    padding-left: 0;
    z-index: 10;

    a {
        border-bottom: 0;
    }

    @media($mq-small) {
        padding-top: 2 * $site-grid-spacing;
        padding-bottom: 2 * $site-grid-spacing;
        padding-left: $site-grid-spacing;
    }
}

.site-header__logo {
    display: block;
    width: 125px;
    transition: width $transition-time ease;

    @media($mq-x-small) {
        width: 175px;
    }

    @media($mq-small) {
        width: 225px;
    }
}

.site-header__links-wrap {
    padding: 0;
    position: relative;

    &:after {
        content: url('/assets/images/svg/speech-bubble.svg');
        position: absolute;
        width: 240px;
        top: -80px;
        right: -75px;
        z-index: 1;
        overflow-x: hidden;
        transition: all $transition-time ease;

        @media($mq-x-small) {
            width: 300px;
            top: -90px;
            right: -110px;
        }

        @media($mq-small) {
            width: 450px;
            top: -140px;
            right: -60px;
        }
    }
}

.site-header__list {
    text-align: right;
    z-index: 10;
    position: relative;
    margin-top: 35px;
    margin-left: 0;
    font-size: $font-size-small;
    transition: margin-top $transition-time ease;

    @media($mq-x-small) {
        margin-top: 46px;
    }

    @media($mq-small) {
        margin-top: 72px;
        font-size: $font-size-larger;
    }
}

.site-header__item {
    list-style-type: none;
    display: inline-block;
    border-right: 2px solid $color-generous-mauve;
    padding-right: 6px;

    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
}

.site-header__link {
    text-decoration: none;
    color: $color-text;
}
