/*====================================*\

  @define utilities;

  Text alignment and transformations

\*====================================*/

.u-text {}


/*------------------------------------*\
  alignment
\*------------------------------------*/
.u-text--left {
    text-align: left;
}

.u-text--center {
    text-align: center;
}

.u-text--right {
    text-align: right;
}

.u-text--justify {
    text-align: justify;
}

.u-text--nowrap {
    white-space: nowrap;
}


/*------------------------------------*\
  transforms
\*------------------------------------*/
.u-text--lowercase {
    text-transform: lowercase;
}

.u-text--uppercase {
    text-transform: uppercase;
}

.u-text--capitalize {
    text-transform: capitalize;
}

/*------------------------------------*\
  decoration
\*------------------------------------*/
.u-text--underline {
    text-decoration: none;
    border-bottom: 0.075em solid currentColor;

    .ie8 & {
        text-decoration: underline;
    }
}

/*------------------------------------*\
  weights
\*------------------------------------*/
.u-text--extrabold {
    font-weight: 800;
}

