/*====================================*\

    @define figure;

    Editorial image block
    with optional caption

\*====================================*/

/*------------------------------------*\
    Variables
\*------------------------------------*/

/*------------------------------------*\
    Styles
\*------------------------------------*/

.figure {}

.figure--img {

}

.figure--embed {

}

.figure__img {
    width: 100%;
    display: block;
}

.figure__caption {
    margin-top: 0 !important;
    background-color: $boxout-color-bg;
    padding: $boxout-padding;
}
